"use client";
import { Button, Stack, Typography } from "@mui/material";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  return (
    <Stack spacing={2} bgcolor={"common.black"}>
      <Typography variant={"h2"}>Something went wrong!</Typography>
      <Typography variant={"h3"}>{error.message}</Typography>
      <Button onClick={() => reset()} variant={"contained"} color={"primary"}>
        Try again
      </Button>
    </Stack>
  );
}
